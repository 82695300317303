<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-lg-4 col-md-6 col-sm-8">
      <ValidationObserver v-slot="{ handleSubmit }">
        <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
        <form @submit.prevent="handleSubmit(submitLogin)">
          <Transition>
            <card class="py-2">
              <div>
                <div class="mb-3 text-center">
                  <img :src="logo" alt="" class="mw-100" width="200" />
                </div>
                <h3 class="card-title text-center mb-2">Login</h3>
                <ValidationProvider
                  name="username"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="text"
                    :error="failed ? 'The Username field is required' : null"
                    :hasSuccess="passed"
                    label="Username"
                    name="username"
                    v-model="username"
                    class="mb-2"
                  >
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  name="password"
                  rules="required|min:5"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="password"
                    :error="failed ? 'The Password field is required' : null"
                    :hasSuccess="passed"
                    name="password"
                    label="Password"
                    v-model="password"
                    autocomplete="false"
                  >
                  </fg-input>
                </ValidationProvider>
                <!-- <ValidationProvider
                  name="Authenticator"
                  rules="required|min:5"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="text"
                    :error="
                      failed ? 'The Authenticator field is required' : null
                    "
                    :hasSuccess="passed"
                    name="Authenticator"
                    label="Google Authenticator"
                    v-model="Authenticator"
                    autocomplete="false"
                  >
                  </fg-input>
                </ValidationProvider> -->
              </div>
              <div class="text-center" v-loading="loading">
                <button
                  type="submit"
                  class="btn btn-fill btn-primary btn-round btn-wd"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> เข้าสู่ระบบ
                </button>
              </div>
            </card>
          </Transition>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

extend("required", required);
extend("min", min);

export default {
  components: {},
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      logo: "/static/img/allslotvip-logo-1000x1000.png",
    };
  },

  methods: {
    submitLogin() {
      if (!this.username || !this.password) {
        alert("กรุณากรอกข้อมูลให้ครบ");
        return;
      }

      Swal.fire("แจ้งเตือน", "กำลังเข้าสู่ระบบ", "");

      Swal.showLoading();

      setTimeout(() => {
        Swal.close();
        const users = [
          { username: "alldashboard", password: "username@#" },
          { username: "admin", password: "hm15gsrfea" },
          // Add more users here as needed
        ];

        const user = users.find(
          (u) => u.username === this.username && u.password === this.password
        );

        if (user) {
          this.$cookies.set("isLogined", true);
          this.$router.push("/");
        } else {
          return Swal.fire("แจ้งเตือน", "ชื่อหรือรหัสผ่านไม่ถูกต้อง", "info");
        }
      }, 500);
    },

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
